:root {
    --primary-color: rgb(23, 156, 30);
    --background-color: #f9f9f9;
    --font-color: #0b0b0c;
    --secondary-color: #949393;
    --tertiary-color: #a3abba;
    --error-color: rgb(236, 68, 96);
    --invert-font-color: #ffffff;
    --font-stack: -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial,
        sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
    --page-width: 60em;
    --display-h1-decoration: none;
    --nav-font-size: 19px;
}

p {
    font-size: 1.15em;
    line-height: 1.5;
}

#root {
    background-color: #ffffff;
}

.h1 {
    font-size: 30px !important;
    text-align: center !important;
    width: 100% !important;
}

.terminal-nav a,
.terminal-prompt {
    font-size: var(--nav-font-size) !important;
}

.hero {
    padding-top: 50px;
}

.flex-parent {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    align-content: center;
}

.flex-l {
    order: 0;
    flex-basis: 60%;
    padding-top: 40px;
    padding-left: 25px;
}

.flex-r-image {
    order: 1;
    flex-basis: 40%;
}

.avatar {
    padding: 5px;
}

.devicon {
    width: 27px;
    height: 27px;
    vertical-align: middle;
}

a:hover {
    text-decoration: none;
}

.tools {
    font-size: 32px;
}

.tools > :first-child {
    margin-left: 15px;
}

.player {
    padding-left: 5em;
    padding-right: 5em;
    text-align: center;
}

.code {
    font-family: Consolas, "courier new" !important;
    color: crimson !important;
    background-color: #f1f1f1 !important;
    padding: 2px !important;
    font-size: 105% !important;
    font-weight: bold;
}

.emoji {
    font-size: 60px !important;
    margin-bottom: 0 !important;
    display: inline-flex;
    align-items: center;
}

.live {
    font-size: 0.35em !important;
    color: red;
}

.social {
    display: inline-flex;
    align-items: center;
    margin-right: 12px;
}

.blink {
    animation: blinker 0.75s cubic-bezier(0.5, 0, 1, 1) infinite alternate;
}
@keyframes blinker {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}
